import React from 'react'
import Metadata from '~/components/Metadata'
import AccountLoginPage from '~/components/Account/Login/Page'
import { useTranslate } from '~/hooks'
import nextContentWrapper from 'graphql-cms/nextContentWrapper'
import type { InferNextPage } from '~/types'
import { getAnnouncementBar } from 'graphql-cms/queries'

export const getStaticProps = nextContentWrapper.getStaticProps(async () => {
  return {
    props: {
      announcementBarConfig: await getAnnouncementBar(),
    },
  }
})

const Login: InferNextPage<typeof getStaticProps> = () => {
  const translate = useTranslate()

  return (
    <>
      <Metadata title={translate('login.page_title')} />
      <AccountLoginPage />
    </>
  )
}

export default Login

/** @jsxImportSource theme-ui */
import { Flex } from 'theme-ui'
import { StytchLogin, StytchProvider } from '@stytch/nextjs'
import { Products, StytchLoginConfig, OAuthProviders } from '@stytch/vanilla-js'
import { createStytchUIClient } from '@stytch/nextjs/ui'
import { useMemo } from 'react'

const StytchLoginForm: React.FC = () => {
  const stytchProps = useMemo<StytchLoginConfig | null>(() => {
    const baseUrl =
      typeof window !== 'undefined' ? window.location.origin : undefined

    if (!baseUrl) {
      return null
    }

    return {
      products: [Products.emailMagicLinks, Products.oauth],
      emailMagicLinksOptions: {
        loginRedirectURL: `${baseUrl}/authenticate`,
        loginExpirationMinutes: 30,
        signupRedirectURL: `${baseUrl}/authenticate`,
        signupExpirationMinutes: 30,
        createUserAsPending: false,
      },
      // these won't work until you have set them up in Stytch
      oauthOptions: {
        loginRedirectURL: `${baseUrl}/authenticate`,
        signupRedirectURL: `${baseUrl}/authenticate`,
        providers: [{ type: OAuthProviders.Google }],
      },
    }
  }, [])

  if (!stytchProps) {
    return null
  }

  return (
    <StytchProvider
      stytch={createStytchUIClient(process.env.NEXT_PUBLIC_STYTCH_API_KEY)}
    >
      <Flex sx={{ flexDirection: 'column', width: '400px', margin: '0 auto' }}>
        <StytchLogin
          config={stytchProps}
          styles={{
            hideHeaderText: true,
            container: {
              borderColor: 'transparent',
              width: '100%',
            },
            buttons: {
              primary: {
                backgroundColor: 'var(--theme-ui-colors-primary)',
              },
            },
          }}
          callbacks={{
            onEvent: (event) => console.log(event),
            onError: (error) => console.log(error),
          }}
        />
      </Flex>
    </StytchProvider>
  )
}

export default StytchLoginForm
